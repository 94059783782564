@import 'bootstrap/scss/bootstrap';

$header-container-spacing-desktop: 40px;
$header-container-spacing-mobile-left: 6px;
$header-container-spacing-mobile-right: 12px;
$header-container-spacing-mobile: 0;

.home-page-header{
    width: 75%;
    @media screen and (max-width: $msv-breakpoint-m) {
        width: 90%;
    }
    margin: 0 auto;
    padding-top: 20px;
    h1, h2 {
        color: $h-blue;
        text-align: center;
    }
    h1 {
        font-weight: 900;
    }
    p {
        color: $h-blue;
        text-align: left;
    }
}

.adjust-p {
    p {
        text-align: center;
    }
}

.flavor {
    .row {
        @media screen and (min-width: $msv-breakpoint-m) {
            flex-wrap: nowrap
        }
    }
    .col-md-6:first-child {
        margin-top: auto;
        margin-bottom: auto;
    }
}

.flavor-text {
    float: left;
    padding-left: 30px;
    
}

.flavor-img {
    float: right;
    padding-right: 30px;
    @media screen and (max-width: $msv-breakpoint-m) {
        float: none;
        padding: 0;
    }
    img {
        max-width: 400px;
        max-height: 400px;
        width: 100%;
        height: auto;
        @media screen and (max-width: $msv-breakpoint-m) {
            max-width: 300px;
            margin: 0 auto;
        }
    }    
}

.text-center{
    text-align: center;
}

.home-page-outer-container{
    width: 100%;
    overflow-x: hidden;
}

.responsive-img {
    img {
        width: 100%;
        height: auto;
    }
}

.home-family {
    width: 75% !important;
    margin: 0 auto;
    padding-top: 5%;
    padding-bottom: 3%;
    padding-left: 10px;
    padding-right: 10px;
    .row {
        width: 100%;
        margin: 0 auto;
    }
    .family {
        .ms-text-block {
            color: var(--h-font-nobg-color);
            h1, h2, h3, h4, h5, h6, p {
                text-align: left;
            }
            h1 {
                font-weight: 900;
            }
        }
    }
}

@media (max-width: $msv-breakpoint-m) {
    .home-family{
        width: 90% !important;    
    }

    .home-family .family .ms-text-block {
        h3{
            text-align: center;
        }
    }

    .flavor-text{
        width: 95%;
    }

    .text-center{
        width: 90%;
        margin: auto;
    }
}
@media (min-width:$msv-breakpoint-xl){
    .responsive-img{
        display:none;
    }
    .home-hero-cover{
        display:block;
    }
}
@media (max-width:$msv-breakpoint-xl){
    .home-hero-cover{
        display:none;
    }
}