.contact-box-width{
  width: 80% !important;
  margin: auto;
  padding-left: 10px;
}

.contact-outer-box-width{
  .row{
    width: fit-content;
    margin: auto;
  }
}

.contact-address-box{
  width: 80%;
  margin: auto;
  .row{
    margin-left: auto;
  }
}

.address-box {
  width: auto;
}

.contact-form {
    padding: 20px 0 10px 0;
    border-top: 10px solid $h-red;
    h1, label{
        color: $h-blue;
        font-weight: bold;
    }

    h1{
      margin-bottom: 1%;
      font-weight: 900;
    }

    input:focus, textarea:focus, select:focus{
        outline: none !important;
        border-color: $h-blue;
        box-shadow: 0 0 3px $h-blue;
    }

    @include font-content(var(--msv-font-weight-normal));
    margin: 2em auto;
    width: 80%;
    form {
      .fg{
        padding: 0 1em;
        display: inline-block;
        vertical-align: top;
      }
  
      .error {
        color: $h-red;
        font-size: small;
      }
  
      textarea{
        @include form-control;
        border-color: #6b727a;
        height: 144px;
        width: 100%;
      }
      input[type="checkbox"] {
        display: inline-block;
        height: 24px;
        vertical-align: middle;
        width: 24px;
      }
      input,select {
        @include form-control;
        border-color: #6b727a;
      }
      .radio-button {
        @include form-input-radio;
      }
      .radio-input {
        @include form-input-radio-label;
        width: calc(100% - 50px);
      }
  
      label {
        @include form-input-label;
        margin-top: 1em;
        &.choice{
          width: 50%;
          vertical-align: middle;
          display: inline-block;
        }
        &.required::after{
          color: $h-red;
          padding-left: .15em;
          content: "*";
        }
      }
      button{
        margin-top: 1em;
        @include primary-button;
        @include font-content-l;
      }
    }
  }

  @media (max-width: $msv-breakpoint-m) {
    h1{
      text-align: center;
    }

    button{
      width: 100%;
    }
  }

  .contact-form-richtext{
    color:$h-blue;
    margin-bottom:5%;
  }