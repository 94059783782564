@media screen and (max-width: $msv-breakpoint-m) {
  div.mobileheading {
    display: block!important;
    > .row {
      display: flex;
      align-items: flex-start !important;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: flex-start;
      .img-block-module picture img {
        width: auto;
        height: 120px;
        float: right;
        position: absolute;
        padding-right: 2em;
      }
    }
  }
  div.burgertime {
    .bun {
      height: 76px;
      width: 76px;
      margin: 0 20px;
      padding: 12px 12px;
      fill: $h-navy;
    }
    display: block !important;
    align-self: flex-start;
    .toppings {
      cursor: pointer;
      display: none;
      background: $h-blue;
      width: 50vw;
      margin-top: 1.5em;
      border-top: 1px solid $h-navy;
      a.ms-nav__list__item__link {
        text-align: left;
        color: white;
        height: 3em;
        padding: 0 2em;
        line-height: 3em;
        font-size: 1.5em;
        font-weight: bold;
        text-transform: uppercase;
        border-bottom: 1px solid white;
        transition: all 0.3s;
        &:last-child {
          border-bottom: none;
        }
        &:active,
        &:target,
        &:hover {
          background-color: $h-navy;
        }
      }
      .row.socials {
        width: 100% !important;
        display: block !important;
        background: white;
        border-width: 0 1px 1px 1px;
        border-style: solid;
        border-color: $h-blue;
        cursor: default;
        margin: 0;
        > div {
          padding: 0.5em 0 0.5em 2em;
          display: flex;
          align-content: stretch;
          justify-content: space-evenly;
          align-items: center;
          flex-wrap: nowrap;
          flex-direction: row;
          > a {
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
              opacity: 0.75;
            }
            > img {
              width: 50px;
              max-height: unset;
              height: 50px;
              max-width: unset;
            }
          }
        }
      }
      .open {
        display: block;

        nav ul {
          flex-direction: column;
        }
      }
    }
  }
  .desktopheading {
    display: none;
  }
}

div.burgertime {
  display: none;
}

div.mobileheading {
  display: none;
}
