.family {
    > .row {
        flex-wrap: nowrap;
        @media screen and (max-width: $msv-breakpoint-m) {
            flex-direction: column;
        }
        > .col-12:first-child {
            display: flex;
            flex-shrink: 2;
            > .img-block-module {
                margin-top: auto;
                margin-bottom: auto;
                @media screen and (max-width: $msv-breakpoint-m) {
                    margin: 0 auto;
                    padding-top: 3%;
                }
                img {
                    height: auto;
                    width: auto;
                }
            }
        }
        > .col-12:last-child {
            display: flex;
            flex-shrink: 1;
            padding-left: 10%;
            @media screen and (max-width: $msv-breakpoint-m) {
                padding-left: 0%
            }
        }
    }
}