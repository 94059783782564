@import 'bootstrap/scss/bootstrap';

.availability {
    padding-top: 5px;
    padding-bottom: 10px;
    width: 100%;
    > .container {
        border-style: solid;
        border-color: var(--h-primary-navy);
        border-width: medium;  
        border-radius: 2px;
        > a {
            color: var(--h-font-nobg-color);
            width: 100%;
        }
        > a-hover {
            color: var(--h-font-nobg-color);
        }
        .row {
            padding: 10px;
            width: 100%; 
            margin: 0 auto;
            > .col-6 {            
                &.img {
                    float: right;
                    margin-top: auto;
                    margin-bottom: auto;
                    padding-left: 5px;
                }
                &.text {
                    float: left;
                    flex-wrap: wrap;
                    padding-right: 5px;
                    h3 {
                        margin-top: auto;
                        margin-bottom: auto;
                        font-size: 1.25rem;
                        font-weight: bold;
                        @media screen and (max-width: $msv-breakpoint-m) {
                            font-size: 1rem;
                        }
                    }
                }   
            }
        }
    }
    img { 
        height: 50px;
        max-width: 100%;
        object-fit: contain;
        float: right;
        @media (max-width: $msv-breakpoint-m) {
            height: auto;
        }
    }
    @media screen and (max-width: $msv-breakpoint-m) {
        padding-top: 2.5px;
    }
}

.avail-container {
    .container {
        padding: 0px;
    }
    width: 75%;
    margin: 0 auto;
    @media screen and (max-width: $msv-breakpoint-m) {
        padding-top: 10px;
        width: 100%
    }
    .header {
        display: flex;
        align-items: center;
        > .line {
            flex: 1;
            height: 3px;
            background-color: var(--h-primary-red);
        }
        > h2 {
            color: var(--h-font-withbg-color);
            padding-top: 10px;
            padding-bottom: 10px;
            display: inline-block;
            height: 110%;
            width: 60%;
            background-color: var(--h-primary-red);
            font-size: 1.75rem;
            @media screen and (max-width: $msv-breakpoint-m) {
                font-size: 1.25rem;
                width: 80%;
            }
        }
    }
}

.details {
    padding-bottom: 10px;
    padding-top: 0px;
    color: var(--s-font-nobg-color);
    @media screen and (max-width: $msv-breakpoint-m) {
        br {
            display: none;
        }
    }
    .img {
        float: left;
        padding-right: 5%;
        padding-bottom: 5%;
        width: 90%;
        @media screen and (max-width: $msv-breakpoint-m) {
            padding-right: 5%;
            padding-bottom: 5%;
            margin: 0;
            width: 100%;
            .ms-media-gallery {
                padding-top: 0px;
            }
        }
    }

    .msc-carousel__item {
        img {
            height: 400px;
            width: auto;
            object-fit: contain;
        }
    }
    
    .row {
        margin: 0 auto;
        .col-12{
            display:flex;
        }
        p{
            text-align: left;
            font-size: small;
        }
    }
    .text{
        width: 75%;
        @media screen and (max-width: $msv-breakpoint-m) {
            width: 100%;
        }
    }
}

.page {
    width: 65%;
    margin: 0 auto;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 0;
    h1 {
        font-weight: 900;
    }
    @media screen and (max-width: $msv-breakpoint-m) {
        width: 90%;
        h1 {
            font-size: 1.75rem;
            text-align: center;
            margin-top: 25px;
        }
        h2 {
            font-size: 1.5rem;
        }
        p {
            font-size: 1rem;
        }
        .row{
            .col-12{
                flex-wrap: wrap;
                overflow-x: hidden;
            }
        }
    }
}
