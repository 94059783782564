.product-list-container{
    margin:10px 15px;
    padding:25px 10px;
    color:$h-navy;
}
.product-list-container__details{
    width:90%;
    margin:0 auto;
    h1 {
        font-weight: 900;
    }
}
.product-list-container__name{
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
}

.product-list-container__description{
    text-align: left;
}

.product-list-block{
    padding:25px 15px;
    justify-content: center;
}
.product-list-container__item{
    justify-content: center;
}
.product-list-block__a,.product-list-block__details{
    width:100%;
}
.product-list-block__name{
    margin-top: 5px;
    padding: 10px 10px;
    color:$h-white;
    background: $h-red;
    font-size: 3vh;
    width: 100%;
    text-align: center;
}

.product-list-block a:hover{
    text-decoration: none;
}
.product-list-block__image-container{
    display:flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
    margin-bottom: 10px;
}
.product-list-block__image-container img{
    padding:10px 10px;
    max-height: 29vh;
    max-width: 100%;
    height:auto;
    width:auto;
    display:block;
}