$msv-order-history-padding-bottom: 28px;
$msv-order-history-heading-margin-bottom: 8px;
$msv-order-history-header-padding-bottom: 20px;
$msv-order-history-order-count-margin-left: 5px;
$msv-order-history-sales-order-padding-top: 40px;
$msv-order-history-sales-order-padding-bottom: 40px;
$msv-order-history-order-information-sales-id-padding-right: 5px;
$msv-order-history-order-information-receipt-id-padding-right: 5px;
$msv-details-order-information-channel-name-margin-bottom: 4px;
$msv-order-history-sales-lines-margin-top: 8px;
$msv-order-history-sales-line-picture-margin-right: 12px;
$msv-order-history-sales-line-picture-margin-top: 12px;
$msv-order-history-sales-line-picture-width: 81px;
$msv-order-history-sales-line-picture-height: 81px;
$msv-order-history-empty-image-width: 81px;
$msv-order-history-btn-order-details-margin-top: 20px;
$msv-order-history-order-information-created-date-padding-right: 5px;
$msv-order-history-order-information-created-date-padding-left: 5px;
$msv-order-history-order-information-count-padding-right: 5px;
$msv-order-history-order-information-count-padding-left: 5px;
$msv-order-history-order-information-amount-padding-left: 5px;
$msv-container-padding-left: 60px;
$msv-container-padding-right: 60px;
$msv-container-width: 100%;
$msv-order-history-group-delivery-heading-margin-right: 3px;
$msv-order-history-btn-keep-shopping-margin-top: 20px;
$msv-order-history-btn-keep-shopping-margin-bottom: 20px;
$msv-order-history-btn-more-margin-top: 20px;
$msv-order-history-alert-margin-top: 20px;
$msv-order-history-alert-margin-bottom: 20px;

//style presets
:root {
    --msv-order-history-border: #{$msv-gray-300};

    // heading
    --msv-order-history-heading-font-color: var(--msv-font-primary-color);
    --msv-order-history-heading-font-size: var(--msv-body-font-size-xl);

    // title
    --msv-order-history-title-font-color: var(--msv-font-primary-color);
    --msv-order-history-title-font-size: var(--msv-body-font-size-l);

    // text
    --msv-order-history-text-font-color: var(--msv-font-primary-color);
    --msv-order-history-text-font-size: var(--msv-body-font-size-m);

    // secondary button
    --msv-order-history-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-order-history-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-order-history-secondary-btn-border: var(--msv-accent-brand-color);
}

.ms-order-history {
    padding-bottom: $msv-order-history-padding-bottom;

    &__heading {
        @include font-content(
            $font-weight: var(--msv-font-weight-normal),
            $font-size: var(--msv-order-history-heading-font-size),
            $line-height: $msv-line-height-xl
        );
        color: var(--msv-order-history-heading-font-color);
        display: inline-block;
        margin-bottom: $msv-order-history-heading-margin-bottom;
    }

    &__header {
        padding-bottom: $msv-order-history-header-padding-bottom;
        border-bottom: 1px solid var(--msv-order-history-border);
    }

    &__ms-order-history-filter {
        @media screen and (min-width: $msv-breakpoint-m) {
            float: right;
        }

        &__expand-filter-button {
            background-color: unset;

            &::after {
                @include msv-icon();
                content: $msv-ChevronDown;
                font-size: var(--msv-header-font-size);
                text-align: center;
                vertical-align: text-bottom;
                line-height: 1.2;
                margin: 0 8px;
            }
        }

        &__expand-filter-button[aria-expanded=true] {
            &::after {
                content: $msv-ChevronUp;
            }
        }

        .msc-popover {
            background-color: var(--msv-header-bg);
            background-clip: padding-box;
            border: 1px solid $msv-gray-500;
            display: block;
            left: 0;
            max-width: $account-links-max-width;
            position: absolute;
            top: 0;
            word-wrap: break-word;
            z-index: 1060;

            a {
                align-items: center;
                display: flex;
                font-size: 16px;
                line-height: 20px;
                padding: 0 20px;
                height: 48px;
                cursor: pointer;

                &:hover {
                    text-decoration: none;
                }
            }

            .msc-btn {
                color: var(--msv-header-font-color);
                font-weight: var(--msv-font-weight-normal);
                height: $popover-menu-item-height;
            }

            .msc-arrow {
                display: block;
                height: 0.5rem;
                margin: 0 0.3rem;
                position: absolute;
                top: calc((0.5rem + 1px) * -1);
                width: 1rem;

                &::before,
                &::after {
                    border-color: transparent;
                    border-style: solid;
                    border-width: 0 0.5rem 0.5rem 0.5rem;
                    content: "";
                    display: block;
                    position: absolute;
                }

                &::before {
                    border-bottom-color: $msv-gray-500;
                    top: 0;
                }

                &::after {
                    border-bottom-color: var(--msv-border-color);
                    top: 1px;
                }
            }
        }
    }

    &__order-count {
        display: inline-block;
        margin-left: $msv-order-history-order-count-margin-left;
    }

    &__sales-order {
        @include font-content-m();
        padding-top: $msv-order-history-sales-order-padding-top;
        padding-bottom: $msv-order-history-sales-order-padding-bottom;
        position: relative;
        border-bottom: 1px solid var(--msv-order-history-border);
    }

    &__order-information-channel-name {
        color: var(--msv-order-history-title-font-color);
        display: block;
        font-size: var(--msv-order-history-title-font-size);
        line-height: $msv-line-height-l;
        margin-bottom: $msv-details-order-information-channel-name-margin-bottom;
    }

    &__order-information-sales-id,
    &__order-information-receipt-id,
    &__order-information-channel-reference-id {
        display: block;
    }

    &__sales-lines {
        margin-top: $msv-order-history-sales-lines-margin-top;
        display: flex;
        flex-wrap: wrap;
    }

    &__sales-line-picture {
        margin-right: $msv-order-history-sales-line-picture-margin-right;
        margin-top: $msv-order-history-sales-line-picture-margin-top;
        width: $msv-order-history-sales-line-picture-width;
        height: $msv-order-history-sales-line-picture-height;
    }

    &__btn-order-details {
        @include secondary-button(
            $bg-color: var(--msv-order-history-secondary-btn-bg),
            $color: var(--msv-order-history-secondary-btn-font-color),
            $border-color: var(--msv-order-history-secondary-btn-border)
        );
        margin-top: $msv-order-history-btn-order-details-margin-top;
    }

    &__order-information-created-date {
        padding-right: $msv-order-history-order-information-created-date-padding-right;
        border-right: 1px solid var(--msv-order-history-border);
    }

    &__order-information-count {
        display: inline-block;
        padding-right: $msv-order-history-order-information-count-padding-right;
        padding-left: $msv-order-history-order-information-count-padding-left;
        border-right: 1px solid var(--msv-order-history-border);
    }

    &__order-information-amount {
        padding-left: $msv-order-history-order-information-amount-padding-left;
    }

    &__groups {
        display: flex;
    }

    &__empty-message,
    &__alert {
        display: block;
        margin-top: $msv-order-history-alert-margin-top;
        margin-bottom: $msv-order-history-alert-margin-bottom;
    }

    &__order-information-sales-id,
    &__order-information-receipt-id,
    &__order-information-channel-reference-id,
    &__order-information-created-date,
    &__order-information-count,
    &__order-information-amount {
        font-size: var(--msv-order-history-text-font-size);
        color: var(--msv-order-history-text-font-color);
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__btn-order-details {
            position: unset;
            width: 100%;
        }

        &__btn-keep-shopping {
            width: 100%;
        }
    }

    .msc-empty_image {
        @include image-placeholder($msv-order-history-empty-image-width);
    }

    &__btn-keep-shopping {
        margin-top: $msv-order-history-btn-keep-shopping-margin-top;

        @include primary-button();
    }

    &__btn-more {
        &.is-busy {
            @include add-spinner(before);
        }

        margin-top: $msv-order-history-btn-more-margin-top;

        @include primary-button();
    }
}
