@import 'bootstrap/scss/bootstrap';
@import "node_modules/bootstrap/scss/bootstrap";

//General Category Page
.category-page-container{
    padding-right: 0 !important;
    padding-left: 0 !important;
    overflow-x: hidden;
    max-width: 95% !important;
    margin: auto;
    
}

.no-title{
    margin-top: 5%;
}

.row-JC-center{
    justify-content: center;
}

.row-JC-spaced-between{
    justify-content: space-between;
}

.row-JC-spaced-evenly{
    justify-content: space-evenly;
}

.category-header-container {
    @media screen and (min-width: $msv-breakpoint-m) {
        max-width: 81% !important;
    }
    @media screen and (min-width: $msv-breakpoint-l) {
        max-width: 87% !important;
    }
    @media screen and (min-width: $msv-breakpoint-xl) {
        max-width: 78% !important;
    }
    text-align: center;
    margin-top: 3.5% !important;
    margin-bottom: 2.5% !important;
    max-width: 100%;
    padding: 0 !important;
    display: flex;
    align-items: center;
    margin:auto;
    
}
.category-a-tag{
    margin: 0;
}

.category-content-block-container {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
    @media (max-width: $msv-breakpoint-m) {
        width: 90%;
        margin: auto;
        margin-bottom: 20px;
    }
}

.category-slogan{
    text-align: center;
    color: $h-blue;
    padding-bottom: 25px;
    margin-bottom: 2.5%;
    overflow-wrap: break-word;
}


/* Custom Class for Main Header */
.category-header {
    position: relative;
    text-align: center;
    color: $h-white;
    background-color: $h-red;
    width: 30%;
    margin: auto;
    padding: 15px 15px 15px 15px;
    font-size: x-large;
    font-weight: 900;
    @media (max-width: $msv-breakpoint-m) {
        width: 80%;
        word-break: break-word;
    }
}

.add-red-line {
    flex: 1;
    height: 3px;
    background-color: $h-red;
}


/* Custom Class for top red border */
.page-top-border-red {
    border-top: solid 10px $h-red;
}

.page-top-border-red2{
    border-top: solid 10px $h-red;
    padding-top: 5%;
}

/* Content Block Header ClassNames */
//First Style
.category-header1{
    h2{
        color: $h-white;
        font-size: large;
        margin: 0;
        padding: 10px;
        width: 200px;
        font-weight: bold;
        overflow-wrap: break-word;
    }
}

.background-fill{
    background-color: $h-red;
}

//Second Style
.category-header2{
    h2{
        color: $h-red;
        font-size: x-large;
        margin: 0;
        padding: 5px;
        font-weight: bold;
        overflow-wrap: break-word;
    }
}

//Third Style
.category-header3{
    border: 3px solid $h-red;
    h2{
        color: $h-white;
        font-size: xx-large;
        margin: 0;
        padding: 15px 25px 15px 25px;
        font-weight: bolder;
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
        overflow-wrap: break-word;
    }
}

/* Image ClassNames */
.outer-category-content-block-container{
    text-align: center;
    margin: 1%;
    margin-top: 15px;
    margin-bottom: 15px;
}

//Adds Red Border
.red-border{
    border: 2px solid $h-red;
}

/* Paragraph Styles */
.p-red {
    color: $h-red;
    text-align: center;
    margin-top: 10px;
    font-size: larger;
}

.p-blue {
    color: $h-blue;
    text-align: center;
    margin-top: 10px;
}
