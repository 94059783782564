
@import 'bootstrap/scss/bootstrap';

:root {
    --blue-font: var(--h-font-nobg-color);
}

.ms-text-block a {
    color: var(--blue-font);   
}

.ms-text-block a:hover{
    color: #0645AD;
}

.blue-font {
    color: var(--blue-font);
    width: 75%;
    margin: 0 auto;
    a{
        text-decoration: none;
      }
      a:hover{
        text-decoration: underline;
      }
      a, a:hover{
        color: var(--blue-font);
      }
    h1{
        text-align: center;
        margin-top: 5%;
    }
}

.responsive-img {
    img {
        width: 100%;
        height: auto;
    }
}

.img-block-across {
    padding: 0px;
    padding-top: 3%;
    &.container {
        width:75%;
        padding-bottom: 5%;
        border-bottom: 10px solid $h-red;
    }
    img {
        height: auto;
        width: auto;
        object-fit: cover;
    }
    .col:first-child {
        img {
            float: left;
            padding-right: 1%;
        }
    }
    .col:nth-child(2) {
        img {
            margin: 0 auto;
            padding-right: 0.5%;
            padding-left: 0.5%;
        }
    }
    .col:last-child {
        img {
            float: right;
            padding-left: 1%;
        }
    }
}

.about-us-container{
    padding-top: 5%;
}

.abt-family {
    width: 75% !important;
    padding-top: 5%;
    padding-bottom: 5%;
    .family {
        .ms-text-block {
            color: var(--blue-font);
        }
        .row {
            width: 100%;
            margin: 0 auto;
        }
    }
}

.abt-socials {
    padding-bottom: 5px;
    img {
        @media screen and (max-width: $msv-breakpoint-m) {
            height: 75px;
            width: 75px;
            object-fit: cover;
        }
        @media screen and (min-width: $msv-breakpoint-m) {
            height: 100px;
            width: 100px;
            object-fit: cover;
        }
    }

    .row {
        width: fit-content;
        margin: auto;
    }
    
    
}
@media (min-width:$msv-breakpoint-xl){
    .responsive-img{
        display:none;
    }
    .about-hero-cover{
        display:block;
    }
}
@media (max-width:$msv-breakpoint-xl){
    .about-hero-cover{
        display:none;
    }
}