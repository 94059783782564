$default-empty-image-width: 150px;

@mixin image-placeholder-url($size) {
    background-image: str-replace(url("data:image/svg+xml,%3Csvg width='#{$size}' height='#{$size}' viewBox='0 0 150 150' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3C!-- background --%3E%3Cpath d='M150 0H0V150H150V0Z' fill='white'/%3E%3C!-- line --%3E%3Cpath d='M100 56.25V93.75H50V56.25H100ZM53.125 59.375V75.9033L64.0625 64.9902L79.6875 80.6152L85.9375 74.3652L96.875 85.2783V59.375H53.125ZM53.125 90.625H85.2783L64.0625 69.3848L53.125 80.3467V90.625ZM96.875 90.625V89.7217L85.9375 78.7598L81.8848 82.8125L89.7217 90.625H96.875ZM89.0625 68.75C88.6393 68.75 88.2731 68.5954 87.9639 68.2861C87.6546 67.9769 87.5 67.6107 87.5 67.1875C87.5 66.7643 87.6546 66.3981 87.9639 66.0889C88.2731 65.7796 88.6393 65.625 89.0625 65.625C89.4857 65.625 89.8519 65.7796 90.1611 66.0889C90.4704 66.3981 90.625 66.7643 90.625 67.1875C90.625 67.6107 90.4704 67.9769 90.1611 68.2861C89.8519 68.5954 89.4857 68.75 89.0625 68.75Z' fill='%23D1D1D1'/%3E%3C/svg%3E%0A"), "#", "%23");
}

@mixin image-placeholder($size) {
    @include image-placeholder-url($size);
    height: $size;
    width: $size;
}

.msc-empty_image {
    @include image-placeholder($default-empty-image-width);
}
